import Cookies from 'js-cookie';

const attributes = { path: '/', sameSite: 'None', secure: true };

const cookieStore = Cookies.withAttributes(attributes);

export default {
    get (key) {
        return cookieStore.get(key);
    },
    set (key, value, options = {}) {
        cookieStore.set(key, value, { ...attributes, ...options });
    },
    remove (key, options = {}) {
        cookieStore.remove(key, { ...attributes, ...options });
    }
}
