import cookieStore from './provider/js-cookie';
import inMemory from './provider/in-memory';

export default {
  get (key) {
    return cookieStore.get(key) || inMemory.get(key);
  },
  set (key, value, options = {}) {
    cookieStore.set(key, value, options);
    inMemory.set(key, value);
  },
  remove (key, options = {}) {
    cookieStore.remove(key, options);
    inMemory.remove(key);
  }
}
