const storage = {};

export default {
  get (key) {
    return storage[key];
  },
  set (key, value) {
    storage[key] = value;
  },
  remove (key) {
    delete storage[key]
  }
}
